import { Helmet } from 'react-helmet-async';

const SEO = ({
  title,
  description,
  name = 'Rec Room Circuits',
  type = 'website',
  image = false,
}) => (
  <Helmet>
    {/* Standard Meta */}
    <title>{title}</title>
    <meta name='description' content={description} />

    {/* Facebook */}
    <meta property="og:type" content={type} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    {image && (
      <meta
        property="og:image"
        content={`https://www.recroomcircuits.com${image}`}
      />
    )}

    {/* Twitter */}
    <meta name="twitter:creator" content={name} />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta
      name="twitter:card"
      content={image ? 'summary_large_image' : type}
    />
    {image && (
      <>
        <meta
          name="twitter:image"
          content={`https://www.recroomcircuits.com${image}`}
        />
      </>
    )}
  </Helmet>
);

export default SEO;