import { navigationPaths } from '../../constants';
import { useNavSync } from '../../hooks';
import logo from '../../images/logo-512x512.png';
import '../../styles/Home.css';
import SEO from '../SEO';

function Home() {
  useNavSync(navigationPaths.HOME);

  return (
    <>
      <SEO
        title='Rec Room Circuits - Where Circuiteers Create'
        description='Rec Room Circuits | The leading resource for everything Rec Room circuit related.'
      />
      <div className='circuit-browser'>
        <div className='content-box'>
          <div>
            <span>Welcome to Rec Room Circuits!</span>
          </div>
          <div style={{ fontSize: 16, fontWeight: 'normal', color: '#eee' }}>
            <p>
              This is a new project to help Rec Room Circuiteers. We are launching the site with a circuit browser (official circuits) and the Chip Generator preview — but we have much more to come!
            </p>
            <p>
              Here is a list of some of our planned features (some are in-progress or beta!):
            </p>
            <ul>
              <li>Circuit Browser</li>
              <ul>
                <li>View all circuits — official and custom.</li>
                <li>Search and filter to find chips faster.</li>
                <li>Convenient page link to share the chip directly with others.</li>
              </ul>
              <li>Circuit Generator</li>
              <ul>
                <li>Create your own custom circuit and have an accurate circuit image generated.</li>
                <li>Save your circuit to be searched and viewed by others.</li>
                <li>Start with an empty board or import an existing circuit to start from.</li>
                <li>Create custom data (port) types for yourself and others to build with.</li>
              </ul>
              <li>User Accounts</li>
              <ul>
                <li>Connect to RecRoomCircuits.com with an account to save your custom circuits, data (port) types, and color palettes.</li>
                <li>Up/Down vote custom-made circuits. Will your chips make the leaderboard?</li>
              </ul>
              <li>Accessibility</li>
              <ul>
                <li>Custom color palettes to change how circuits are generated. Create your own or use a pre-existing palette.</li>
              </ul>
            </ul>
            <p>
              Check out the navigation on the left side (for large screens) or click the menu button next to the logo (smaller screen, mobile) to find your way around the site.
            </p>
            <p>
              <div>Thanks for stopping by,</div>
              <div style={{ fontWeight: 500 }}>PeteBrick#7620</div>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
