import PORT_TYPES from "../../../constants/circuits/portTypes";
import { FONTS, LETTER_SPACING } from "../config";

const DEFAULT_PORT_WIDTH_INPUT = 13;
const DEFAULT_PORT_WIDTH_OUTPUT = 17;

const DEFAULT_POPULATED_PORT_WIDTH = 33;

const checkForWidest = (ctx, xPut, widestPortSize, defaultPortWidth, isOutput = false) => {
  const portTypeInfo = PORT_TYPES[xPut.portTypeColorId];
  const portSize = portTypeInfo.hasDefaultValue && !isOutput ? (
    ctx.measureText(portTypeInfo.defaultValue).width + DEFAULT_POPULATED_PORT_WIDTH
  ) : defaultPortWidth;

  return Math.max(
    widestPortSize,
    portSize,
  )
}

const getPortDimensions = (ctx, functions) => {
  ctx.font = FONTS.PORT_VALUE
  ctx.letterSpacing = LETTER_SPACING.PORT_VALUE_TEXT;

  let widestInputPortSize = 0;
  let widestOutputPortSize = 0;

  functions.forEach(({ inputs, outputs }) => {
    const portRange = Math.max(inputs.length, outputs.length);

    for (let portIndex = 0; portIndex < portRange; portIndex++) {
      const input = inputs[portIndex];
      const output = outputs[portIndex];

      if (input) {
        widestInputPortSize = checkForWidest(
          ctx,
          input,
          widestInputPortSize,
          DEFAULT_PORT_WIDTH_INPUT,
        );
      }

      if (output) {
        widestOutputPortSize = checkForWidest(
          ctx,
          output,
          widestOutputPortSize,
          DEFAULT_PORT_WIDTH_OUTPUT,
          true,
        );
      }
    }
  });

  return {
    inputPortsWidth: widestInputPortSize,
    outputPortsWidth: widestOutputPortSize,
  }
};

export default getPortDimensions;