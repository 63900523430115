import PORT_TYPES from "../../../constants/circuits/portTypes";
import drawInputPortBasic from "./drawInputPortBasic";
import drawInputPortExec from "./drawInputPortExec";
import drawInputPortWithValue from "./drawInputPortWithValue";

const drawInputPort = (
  ctx,
  input,
  horizontalOffset,
  verticalOffset
) => {
  const { portTypeColorId, isList } = input;
  const portTypeInfo = PORT_TYPES[portTypeColorId];

  ctx.fillStyle = portTypeInfo.color;
  if (portTypeColorId === PORT_TYPES.exec.id) {
    drawInputPortExec(
      ctx,
      horizontalOffset,
      verticalOffset
    );
  } else if (portTypeInfo.hasDefaultValue && !isList) {
    drawInputPortWithValue(
      ctx,
      horizontalOffset,
      verticalOffset,
      portTypeInfo.defaultValue,
    )
  } else {
    drawInputPortBasic(
      ctx,
      horizontalOffset,
      verticalOffset,
    )
  }
};

export default drawInputPort;