import { FONTS, LETTER_SPACING } from "../config";

const getHeaderTextWidth = (headerText, ctx) => {
  const currentFont = ctx.font;
  ctx.font = FONTS.HEADER;
  ctx.letterSpacing = LETTER_SPACING.HEADER_TEXT;
  const { width: headerTextWidth } = ctx.measureText(headerText);
  ctx.font = currentFont;

  return headerTextWidth;
};

export default getHeaderTextWidth;
