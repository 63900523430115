import { FILL_STYLES, FONTS, LETTER_SPACING } from "../config";
import { getHeaderTextWriteCoordinates } from "../util";

const writeChipHeaderName = (ctx, headerText, offsetLeft, chipBoardWidth) => {
  const {
    writeAtX,
    writeAtY,
  } = getHeaderTextWriteCoordinates(offsetLeft, chipBoardWidth);

  ctx.textAlign = 'center';
  ctx.font = FONTS.HEADER;
  ctx.letterSpacing = LETTER_SPACING.HEADER_TEXT;
  ctx.fillStyle = FILL_STYLES.HEADER_TEXT;
  ctx.lineJoin = 'round';
  ctx.strokeStyle = '#333';
  ctx.lineWidth = 2;
  ctx.strokeText(headerText, writeAtX, writeAtY);
  ctx.fillText(headerText, writeAtX, writeAtY);
  
};

export default writeChipHeaderName;