import FilterCheckbox from "./Forms/FilterCheckbox";

const ToggleOptions = ({
  betaChecked,
  onBetaCheckboxChange,
}) => {
  return (
    <>
      <FilterCheckbox
        checkboxKey='any'
        label='Any'
        checked={betaChecked.any}
        onCheckboxChange={onBetaCheckboxChange}
        type='radio'
      />
      <FilterCheckbox
        checkboxKey='isBeta'
        label='Is Beta'
        checked={betaChecked.isBeta}
        onCheckboxChange={onBetaCheckboxChange}
        type='radio'
      />
      <FilterCheckbox
        checkboxKey='isNotBeta'
        label='Is Not Beta'
        checked={betaChecked.isNotBeta}
        onCheckboxChange={onBetaCheckboxChange}
        type='radio'
      />
    </>
  );
};

export default ToggleOptions;