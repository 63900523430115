import navigationPaths from "./navigationPaths";
import { ReactComponent as HomeIcon } from '../images/icons/Home.svg';
import { ReactComponent as CircuitGeneratorIcon } from '../images/icons/CircuitGenerator.svg';
import { ReactComponent as CircuitBrowserIcon } from '../images/icons/CircuitBrowser.svg';

const navigationItems = [
  {
    name: 'Home',
    title: 'Rec Room Circuits',
    path: navigationPaths.HOME,
    info: 'A landing page.',
    icon: HomeIcon,
    sort: 1,
    enabled: true,
  },
  {
    name: 'Circuit Generator',
    title: 'Circuit Generator',
    path: navigationPaths.CIRCUIT_GENERATOR,
    info: 'Generate a circuit image with a supplied Circuit JSON.',
    icon: CircuitGeneratorIcon,
    sort: 3,
    enabled: true,
  },
  {
    name: 'Circuit Browser',
    title: 'Circuit Browser',
    path: navigationPaths.CIRCUIT_BROWSER,
    info: 'Find and display official circuits with this circuit browser.',
    // icon: () => <CircuitBrowserIcon style={{ fill: 'white !important' }} />,
    icon: CircuitBrowserIcon,
    sort: 2,
    enabled: true,
  },
]

export default navigationItems;