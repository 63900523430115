const drawText = (x, y, text, ctx) => {
  ctx.font = "16px Arial, sans-serif"
  ctx.fillStyle = '#eeeeee';
  ctx.fillText(text, x, y);
}

const drawInputText = (x, y, text, ctx) => {
  ctx.font = "14px Arial, sans-serif"
  ctx.fillStyle = '#eeeeee';
  ctx.fillText(text, x, y);
}

const drawLabelText = (x, y, text, ctx) => {
  ctx.font = "14px Arial, sans-serif"
  ctx.fillStyle = '#eeeeee';
  ctx.fillText(text, x, y);
}

const drawCircuit = (ctx, canvas, circuitData = {}) => {
  const {
    headerText = 'Rec Room Circuits',
    boolText = 'created by PeteBrick',
  } = circuitData;

  const leftOffset = 50;
  const calcLeftOffset = (x) => {
    return x + leftOffset;
  }

  ctx.font = "16px Arial, sans-serif";
  const { width: headerTextWidth } = ctx.measureText(headerText);

  const circuitWidth = Math.max(160, headerTextWidth + 60);

  const targetWidth = 400;
  const targetHeight = 300;

  const browserScale = Math.min(targetWidth, canvas.offsetWidth) / targetWidth;
  const displayWidth = targetWidth * browserScale;
  const displayHeight = targetHeight * browserScale;
  const headerTextLeftPadding = (circuitWidth - headerTextWidth) / 2;

  const scale = window.devicePixelRatio;
  // const scale = 1;
  canvas.style.width = `${displayWidth}px`;
  canvas.style.height = `${displayHeight}px`;

  canvas.width = Math.floor(displayWidth * scale);
  canvas.height = Math.floor(displayHeight * scale);

  ctx.scale(scale, scale);

  ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)

  // top circuit bar dark gray
  ctx.beginPath();
  ctx.fillStyle = '#515051';
  ctx.roundRect(calcLeftOffset(30), 0, circuitWidth, 40, [10, 10, 0, 0]);
  ctx.fill();

  // main circuit bar gray
  ctx.beginPath();
  ctx.fillStyle = '#828182';
  ctx.roundRect(calcLeftOffset(30), 40, circuitWidth, 90, [0, 0, 10, 10]);
  ctx.fill();

  // circuit header text
  drawText(calcLeftOffset(30 + headerTextLeftPadding), 26, headerText, ctx);

  // bool input
  ctx.beginPath();
  ctx.fillStyle = '#FF3953';
  ctx.roundRect(calcLeftOffset(20), 74, 20, 16, [2, 2, 2, 2]);
  ctx.fill();

  ctx.beginPath();
  ctx.fillStyle = '#FF3953';
  ctx.roundRect(calcLeftOffset(12), 78, 10, 8, [0, 0, 0, 0]);
  ctx.fill();

  ctx.beginPath();
  ctx.fillStyle = '#FF3953';
  ctx.roundRect(calcLeftOffset(-35), 72, 47, 20, [2, 2, 2, 2]);
  ctx.fill();

  ctx.beginPath();
  ctx.fillStyle = '#9D818B';
  ctx.roundRect(calcLeftOffset(-32), 75, 40, 14, [2, 2, 2, 2]);
  ctx.fill();

  drawInputText(calcLeftOffset(-27), 87, 'True', ctx);

  drawLabelText(calcLeftOffset(45), 87, boolText, ctx);
};

export default drawCircuit;