import '../../styles/App.css';
import Header from '../Header';
import Navigation from '../Navigation';
import ScreenSwitch from '../ScreenSwitch';
import '../../styles/breakpoints.css';
import { useAppContext } from '../../context/AppContext';
import { classNames } from '../../utils';

function App() {
  const { isNavOpen } = useAppContext();

  return (
    <div className={classNames([
      ['app-container', true],
      ['navigation-open', isNavOpen]
    ])}>
      <Header />
      <div className="app-primary">
        <Navigation />
        <ScreenSwitch />
      </div>
    </div>
  );
}

export default App;
