import { PADDINGS, SPACINGS } from "../config";

const getHeaderTextWriteCoordinates = (offsetLeft, chipBoardWidth) => {
  const writeAtX = offsetLeft + (chipBoardWidth / 2);
  const writeAtY = (PADDINGS.HEADER.TEXT.VERTICAL / 2) + SPACINGS.HEADER_PER_TEXT_LINE;

  return {
    writeAtX,
    writeAtY,
  }
};

export default getHeaderTextWriteCoordinates;