import { useState } from 'react';
import '../../../styles/Filters.css';
import FilterBody from './FilterBody';
import FilterHeader from './FilterHeader';
import FilterPreview from './FilterPreview';

const Filters = ({
  searchTerm,
  setSearchTerm,
  setPagination,
  betaChecked,
  onBetaCheckboxChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleIsOpen = () => setIsOpen((prevIsOpen) => !prevIsOpen);

  return (
    <>
      <div className='content-box' style={{
        padding: '8px 24px'
      }}>
        <div className='filters-container'>
          <FilterHeader
            isOpen={isOpen}
            toggleIsOpen={toggleIsOpen}
          />
          {isOpen && (
            <FilterBody
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              setPagination={setPagination}
              betaChecked={betaChecked}
              onBetaCheckboxChange={onBetaCheckboxChange}
            />
          )}
        </div>
      </div>
      <FilterPreview
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        betaChecked={betaChecked}
        onBetaCheckboxChange={onBetaCheckboxChange}
      />
    </>
  )
};

export default Filters;