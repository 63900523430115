import { Routes, Route } from "react-router-dom";
import { navigationPaths } from "../../constants";
import CircuitBrowser from "../CircuitBrowser";
import CircuitGenerator from "../CircuitGenerator";
import CircuitViewer from "../CircuitViewer";
import Home from "../Home";

const ScreenSwitch = () => {
  return (
    <div className="app-main">
      <Routes>
        <Route path={navigationPaths.HOME} element={<Home />} />
        <Route path={navigationPaths.CIRCUIT_GENERATOR} element={<CircuitGenerator />} />
        <Route path={navigationPaths.CIRCUIT_BROWSER} element={<CircuitBrowser />} />
        <Route path={navigationPaths.CIRCUIT_VIEWER} element={<CircuitViewer />} />
      </Routes>
    </div>
  );
};

export default ScreenSwitch;
