import { useMemo, useState } from "react";
import useFilterBeta from "./useFilterBeta";

const filterSearchCallback = (circuit, searchTerm) => (
  circuit
    .ReadonlyPaletteName
    .toLowerCase()
    .includes(searchTerm.toLowerCase())
);

const filterCallback = (searchTerm, betaChecked) => (circuit) => {
  return (
    circuit.DeprecationStage !== 'Deprecated'
  ) && (
      searchTerm.length ? filterSearchCallback(circuit, searchTerm) : true
    ) && (
      betaChecked.any ? true : (
        circuit.IsBetaChip === betaChecked.isBeta
      )
    );

};

const useFilter = (circuits) => {
  const [searchTerm, setSearchTerm] = useState('');
  const {
    betaChecked,
    onBetaCheckboxChange,
  } = useFilterBeta();

  const filteredCircuits = useMemo(
    () => circuits.filter(filterCallback(searchTerm, betaChecked)),
    [circuits, searchTerm, betaChecked]
  );

  return {
    searchTerm,
    setSearchTerm,
    betaChecked,
    onBetaCheckboxChange,
    filteredCircuits,
    filteredCircuitsCount: filteredCircuits.length,
  };
};

export default useFilter;