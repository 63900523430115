import { Link } from "react-router-dom";
import { classNames } from "../../utils";
import CircuitDraw from "../CircuitDraw";

const getCircuitLink = (chipName) => {
  const chipSlug = chipName.toLowerCase().trim().replaceAll(' ', '-');
  return `/chip/${chipSlug}`;
}

const CircuitList = ({
  displayCircuits,
  displayRangeLow,
  displayRangeHigh,
  filteredCircuitsCount,
}) => {


  return (
    <div className='content-box'>
      <div style={{ display: 'flex' }}>
        <div>
          <span>Circuits</span>
        </div>
        <div style={{ marginLeft: 12 }}>
          <span
            style={{
              fontSize: 12,
              color: '#ccc',
            }}
          >{filteredCircuitsCount === 0 ? 'No Results' : `${displayRangeLow}-${displayRangeHigh} of ${filteredCircuitsCount}`}</span>
        </div>
      </div>
      <div className='circuit-list'>
        {displayCircuits.map(({ ReadonlyPaletteName, Description, IsBetaChip, ...chip }) => (
          <Link
            to={getCircuitLink(ReadonlyPaletteName)}
            className='circuit-list-item'
            key={ReadonlyPaletteName}
          >
            <div className='circuit-list-item-tags'>
              <div className={classNames([
                ['circuit-list-item-tag', true],
                ['circuit-list-item-tag-official', true]
              ])}>
                <span>OFFICIAL</span>
              </div>
              {IsBetaChip && (
                <div className={classNames([
                  ['circuit-list-item-tag', true],
                  ['circuit-list-item-tag-beta', true]
                ])}>
                  <span>BETA</span>
                </div>
              )}
            </div>
            <div className='circuit-list-item-name'>
              <span>{ReadonlyPaletteName}</span>
            </div>
            <div className='circuit-list-item-desc'>
              <span>{Description}</span>
            </div>
            <div className='circuit-list-draw-box'>
              <CircuitDraw chip={chip} headerText={ReadonlyPaletteName} />
            </div>
          </Link>
        ))
        }
      </div >
    </div>
  );
};

export default CircuitList;