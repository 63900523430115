import { useEffect } from "react";
import { useAppContext } from "../context/AppContext"

const useNavSync = (path) => {
  const { activeNavTab, setActiveNavTab } = useAppContext();

  useEffect(() =>{
    if(activeNavTab !== path) {
      setActiveNavTab(path);
    }
  }, [activeNavTab, setActiveNavTab, path]);
};

export default useNavSync;
