import circuitData from '../constants/circuits/circuitData.json';

// Pull in Circuit JSON
const sortedCircuits = Object.values(circuitData.Nodes)
  .sort((a, b) => a.ReadonlyPaletteName.localeCompare(b.ReadonlyPaletteName));


// Build filter types (categories)
const filterTypes = new Set();
Object.values(sortedCircuits).forEach(({ NodeFilters }) => {
  NodeFilters.forEach(({ FilterPath }) => {
    FilterPath.forEach((filterType) => {
      filterTypes.add(filterType);
    })
  });
});

const circuitFilterTypes = [...filterTypes].sort();

const getCircuitData = () => ({
  sortedCircuits,
  circuitFilterTypes,
});

export default getCircuitData;