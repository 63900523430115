import { useEffect, useRef } from "react";
import PORT_TYPES from "../../constants/circuits/portTypes";
import { useAppContext } from "../../context/AppContext";
import { useCanvas } from "../../hooks";
import { drawChip } from "../../utils";
import reportError from "../../utils/reportError";

const CircuitDraw = ({ chip, headerText }) => {
  const { width, height } = useAppContext();
  // const { canvasRef, ctx, canvas } = useCanvas();
  const canvasRef = useRef();


  useEffect(() => {
    try {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');

      // Trick to fit canvas into parent element size
      canvas.style.width = "100%";
      canvas.style.height = "100%";
      canvas.style.width = canvas.offsetWidth;
      canvas.style.height = canvas.offsetHeight;

      const derivePortType = (type = '') => {
        const listRegex = /List\<([a-zA-z]+)\>/;
        const matches = type.match(listRegex);

        if (matches) {
          return {
            derivedPortType: matches[1],
            isList: true,
          };
        }

        return {
          derivedPortType: type,
          isList: false,
        };
      };

      const processXPut = (xPut, ReadonlyTypeParams) => {
        const ports = [];

        xPut.forEach(({ Name, ReadonlyType }) => {
          const {
            derivedPortType,
            isList,
          } = derivePortType(ReadonlyType);
          const multiType = ReadonlyTypeParams[derivedPortType];

          let portTypeColorId = PORT_TYPES[derivedPortType]?.id || PORT_TYPES.object.id;
          let portTypeLabel = derivedPortType;
          if (multiType) {
            portTypeLabel = multiType;
            portTypeColorId = PORT_TYPES.generic.id;
            // const types = multiType.slice(1, -1).split(' | ');

            // const typeColors = new Set(
            //   types.map((typeId) => (
            //     PORT_TYPES[typeId]?.color || PORT_TYPES.generic.color
            //   ))
            // );

            // if (typeColors.size === 1) {
            //   if (PORT_TYPES[types[0]]) {
            //     portTypeColorId = PORT_TYPES[types[0]].id;
            //   } else {
            //     portTypeColorId = PORT_TYPES.generic.id;
            //   }
            // }
          }

          ports.push({
            portLabel: Name,
            portTypeLabel,
            portTypeColorId,
            isList,
          })
        });

        return [...ports];
      }

      const chipFunctions = chip.NodeDescs.map(({
        Inputs,
        Outputs,
        ReadonlyTypeParams,
      }) => {
        const inputs = processXPut(Inputs, ReadonlyTypeParams);
        const outputs = processXPut(Outputs, ReadonlyTypeParams);

        return {
          inputs,
          outputs,
        };
      });

      const chipData = {
        headerText: headerText || undefined,
        functions: chipFunctions,
      };
      drawChip(chipData, ctx, canvas);
    } catch (e) {
     
    }
  }, [canvasRef.current, width, height, headerText]);

  return (
    <canvas
      ref={canvasRef}
    ></canvas>
  );
}

export default CircuitDraw;