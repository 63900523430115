import { useCallback, useState } from "react";

const betaDefaultState = {
  any: true,
  isBeta: false,
  isNotBeta: false,
};

const betaChangeState = {
  any: false,
  isBeta: false,
  isNotBeta: false,
};

const useFilterBeta = () => {
  const [betaChecked, setBetaChecked] = useState(betaDefaultState);

  const onBetaCheckboxChange = useCallback((checkboxKey) => {
    setBetaChecked({
      ...betaChangeState,
      [checkboxKey]: true,
    });
  }, [setBetaChecked]);

  return {
    betaChecked,
    onBetaCheckboxChange,
  };
};

export default useFilterBeta;