const PADDINGS = {
  HEADER: {
    TEXT: {
      SIDE: 20,
      VERTICAL: 10,
    }
  },
  BODY_VERTICAL: 12,
  BODY_HORIZONTAL: 12,
};

export default PADDINGS;