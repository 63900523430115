import { classNames } from "../../utils";

const getTopPaginationCoords = () => {
  const filterPreviewElement = document.querySelector('.filter-preview');
  const topPaginationElement = document.querySelector('.pagination');

  const targetElement = filterPreviewElement.hasChildNodes() ? (
    filterPreviewElement
  ) : topPaginationElement;

  if (targetElement) {
    const box = targetElement.getBoundingClientRect();

    return {
      top: box.top + window.pageYOffset,
      right: box.right + window.pageXOffset,
      bottom: box.bottom + window.pageYOffset,
      left: box.left + window.pageXOffset
    };
  }

  return {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  };
};

const paginationUtil = (setPagination) => {
  const displayPages = [];

  const addPage = (pageNum) => {
    displayPages.push({
      props: {
        className: 'pagination-item',
        onClick: () => {
          const scrollTarget = getTopPaginationCoords().top - 75;

          window.scrollTo(0, scrollTarget);
          setPagination(pageNum)
        }
      },
      value: pageNum,
    });
  }

  const addSeparator = () => {
    displayPages.push({
      props: {
        className: 'pagination-separator',
      },
      value: '...',
    });
  }

  return {
    displayPages,
    addPage,
    addSeparator,
  }
};


const Pagination = ({
  numPages,
  pagination,
  setPagination,
  side,
}) => {
  const {
    displayPages,
    addPage,
    addSeparator,
  } = paginationUtil(setPagination);

  if (numPages > 1) {
    if (numPages <= 5) {
      // If we have 5 or less pages, we will simply display all of them
      [...Array(numPages).keys()].forEach((i) => addPage(i + 1));
    } else {
      const needsStartSeparator = pagination > 3;
      const needsEndSeparator = pagination < (numPages - 2)

      addPage(1);

      if (needsStartSeparator) {
        addSeparator();
      }

      if (numPages - pagination <= 1) {
        addPage(pagination - 2);
      }

      if (pagination > 2) {
        addPage(pagination - 1);
      }

      if (pagination > 1) {
        addPage(pagination);
      }

      if (pagination < numPages) {
        addPage(pagination + 1);
      }

      if (pagination <= 2) {
        addPage(pagination + 2);
      }

      if (needsEndSeparator) {
        addSeparator();
      }

      if ((pagination + 1) < numPages) {
        addPage(numPages);
      }
    }
  }

  return (
    <div
      className='pagination'
      style={side === 'bottom' ? {
        marginTop: 8,
        marginBottom: 12,
      } : {}}
    >
      {displayPages.map(({ props: { className, onClick }, value }, index) => (
        <div
          className={classNames([
            [className, true],
            ['pagination-selected', value === pagination]
          ])}
          onClick={onClick}
          key={`${value}-${index}`}
        >
          <span>
            {value}
          </span>
        </div>
      ))}
    </div>
  )
};

export default Pagination;