const CLOSE_CHARACTER = '✕';

const FilterPreviewItem = ({
  label,
  onDismiss,
}) => {

  return (
    <div className='filter-preview-item'>
      <div className='filter-preview-item-label'>
        <span>{label}</span>
      </div>
      <div
        className='filter-preview-item-close'
        onClick={onDismiss}
      >
        <span>{CLOSE_CHARACTER}</span>
      </div>
    </div>
  )
};

export default FilterPreviewItem;