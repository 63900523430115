import { useMemo } from 'react';
import circuitData from '../constants/circuits/circuitData.json'

const useCircuitViewer = (chipSlug) => {
  const chip = useMemo(() => Object.values(circuitData.Nodes).find((circuit) => {
    return chipSlug === circuit.ReadonlyPaletteName.toLowerCase().trim().replaceAll(' ', '-') && circuit.DeprecationStage !== 'Deprecated';
  }), [chipSlug]);

  return {
    chip,
    chipFound: chip !== undefined,
  };
};

export default useCircuitViewer;