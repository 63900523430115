import { useEffect, useRef, useState } from "react";

const useCanvas = (path) => {
  const [ctx, setCtx] = useState(undefined);
  const canvasRef = useRef();
  const canvas = canvasRef.current;
  
  useEffect(() => {
    if(canvas !== undefined) {
      setCtx(canvas.getContext('2d'));
    }
  }, [canvas]);

  return {
    canvasRef,
    canvas,
    ctx,
  }
};

export default useCanvas;

