import PORT_TYPES from "../../../constants/circuits/portTypes";
import drawOutputPortBasic from "./drawOutputPortBasic";
import drawOutputPortExec from "./drawOutputPortExec";

const drawOutputPort = (
  ctx,
  input,
  horizontalOffset,
  verticalOffset
) => {
  const { portTypeColorId } = input;
  const portTypeInfo = PORT_TYPES[portTypeColorId];

  ctx.fillStyle = portTypeInfo.color;
  if (portTypeColorId === PORT_TYPES.exec.id) {
    drawOutputPortExec(
      ctx,
      horizontalOffset,
      verticalOffset
    );
  } else {
    drawOutputPortBasic(
      ctx,
      horizontalOffset,
      verticalOffset,
    )
  }
};

export default drawOutputPort;