import { getCircuitData } from "../utils";

const useCircuitData = () => {
  const {
    sortedCircuits,
    circuitFilterTypes,
  } = getCircuitData();

  return {
    sortedCircuits,
    circuitFilterTypes,
  };
};

export default useCircuitData;