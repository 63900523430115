import { FONTS, LETTER_SPACING, PADDINGS, SPACINGS } from "../config";

const checkForWidest = (ctx, ports, currentWidest) => {
  let calculatedWidest = currentWidest;

  ports.forEach(({ portLabel }) => {
    calculatedWidest = Math.max(
      calculatedWidest,
      ctx.measureText(portLabel).width,
    );
  });

  return calculatedWidest;
};

const getBodyDimensions = (ctx, functions = []) => {
  let widestInputSize = 0;
  let widestOutputSize = 0;
  let totalHeight = 0;

  ctx.font = FONTS.PORT_LABEL;
  ctx.letterSpacing = LETTER_SPACING.PORT_LABEL_TEXT;

  functions.forEach(({ inputs, outputs }) => {
    totalHeight += (
      Math.max(inputs.length, outputs.length) *
      SPACINGS.PORT_PER_ROW
    );

    widestInputSize = checkForWidest(ctx, inputs, widestInputSize);
    widestOutputSize = checkForWidest(ctx, outputs, widestOutputSize);
  });

  if (totalHeight > 0) {
    totalHeight += (PADDINGS.BODY_VERTICAL * 2);
  }

  return {
    bodyWidth: widestInputSize + widestOutputSize + (PADDINGS.BODY_HORIZONTAL * 3),
    bodyHeight: totalHeight,
  };
};

export default getBodyDimensions;