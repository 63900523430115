const PORT_TYPES = {
  generic: {
    id: 'generic',
    color: '#bbbbbb',
    hasDefaultValue: false,
  },
  any: {
    id: 'any',
    color: '#bbbbbb',
    hasDefaultValue: false,
  },
  exec: {
    id: 'exec',
    color: '#ff6619',
    hasDefaultValue: false,
  },
  object: {
    id: 'object',
    color: '#FBE759',
    hasDefaultValue: false,
  },
  bool: {
    id: 'bool',
    color: '#ff4848',
    hasDefaultValue: true,
    defaultValue: 'False',
  },
  float: {
    id: 'float',
    color: '#21afff',
    hasDefaultValue: true,
    defaultValue: '0',
  },
  int: {
    id: 'int',
    color: 'green',
    hasDefaultValue: true,
    defaultValue: '0',
  },
  string: {
    id: 'string',
    color: '#8113bc',
    hasDefaultValue: true,
    defaultValue: `''`,
  },
  Color: {
    id: 'Color',
    color: '#1c1c97',
    hasDefaultValue: false,
  }
};

export default PORT_TYPES;