import React from "react";
import { useNavigate, useParams } from "react-router";
import { navigationPaths } from "../../constants";
import { useCircuitViewer, useNavSync } from "../../hooks";
import { classNames } from "../../utils";
import CircuitDraw from "../CircuitDraw";
import SEO from "../SEO";

const portCallback = (portArr, typeParams) => ({ Name, ReadonlyType }) => {
  const type = ReadonlyType
    .replace(/<(T[0-9]{1,2})>/, (match, p1) => typeParams[p1])
    .replace(/<(T)>/, (match, p1) => `<${typeParams[p1]}>`)
    .replace(/^(T[0-9]{1,2})/, (match) => typeParams[match] || match)
    .replace(/^(T)/, (match) => typeParams[match] || match);

  portArr.push({
    name: Name,
    type
  })
};

const CircuitViewer = ({ }) => {
  useNavSync(navigationPaths.CIRCUIT_BROWSER);
  const navigate = useNavigate();
  const { chipSlug } = useParams();
  const {
    chip,
    chipFound,
  } = useCircuitViewer(chipSlug);

  React.useEffect(() => {
    if (!chipFound) {
      navigate(navigationPaths.CIRCUIT_BROWSER);
    }
  }, [chipFound]);

  if (!chipFound) {
    return null;
  }

  const {
    ReadonlyPaletteName,
    Description,
    IsBetaChip,
    NodeDescs,
  } = chip;

  const chipInputs = [];
  const chipOutputs = [];
  NodeDescs.forEach((node) => {
    node.Inputs.forEach(portCallback(chipInputs, node.ReadonlyTypeParams));
    node.Outputs.forEach(portCallback(chipOutputs, node.ReadonlyTypeParams));
  });

  const defaultSeoDescription = 'A Rec Room circuit.';

  return (
    <>
      <SEO
        title={ReadonlyPaletteName}
        description={Description.trim().length ? Description : defaultSeoDescription}
        image={`/image/circuit/${chipSlug}`}
      />
      <div className='circuit-browser'>
        <div className='content-box'>
          <div className='circuit-list-item-tags'>
            <div className={classNames([
              ['circuit-list-item-tag', true],
              ['circuit-list-item-tag-official', true]
            ])}>
              <span>OFFICIAL</span>
            </div>
            {IsBetaChip && (
              <div className={classNames([
                ['circuit-list-item-tag', true],
                ['circuit-list-item-tag-beta', true]
              ])}>
                <span>BETA</span>
              </div>
            )}
          </div>
          <div className='circuit-list-item-name'>
            <span>{ReadonlyPaletteName}</span>
          </div>
          <div className='circuit-list-item-desc'>
            <span>{Description}</span>
          </div>

          <div className='circuit-viewer-ports' style={{ marginTop: 24 }}>
            <div className='circuit-viewer-ports-header'>
              <span>Inputs</span>
            </div>
            <div className='circuit-viewer-ports-list'>
              {chipInputs.length === 0 && (
                <span className='circuit-viewer-ports-none'>No Inputs</span>
              )}
              {chipInputs.map(({ name, type }) => (
                <div className='circuit-viewer-ports-item' key={`input-${name}-${type}`}>
                  <div>
                    <span>{name}</span>
                    <div>
                      <span className='circuit-viewer-ports-item-type'>{'<'}{type}{'>'}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className='circuit-viewer-ports' style={{ marginTop: 24 }}>
            <div className='circuit-viewer-ports-header'>
              <span>Outputs</span>
            </div>
            <div className='circuit-viewer-ports-list'>
              {chipOutputs.length === 0 && (
                <span className='circuit-viewer-ports-none'>No Outputs</span>
              )}
              {chipOutputs.map(({ name, type }) => (
                <div className='circuit-viewer-ports-item' key={`input-${name}-${type}`}>
                  <div>
                    <span>{name}</span>
                    <div>
                      <span className='circuit-viewer-ports-item-type'>{'<'}{type}{'>'}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div style={{ marginTop: 20 }}>
            <CircuitDraw chip={chip} headerText={ReadonlyPaletteName} />
          </div>
        </div>
      </div>
    </>
  );
}

export default CircuitViewer;