import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  useCircuitData,
  useFilter,
  usePagination
} from '../../hooks';

const BrowseContext = React.createContext({
  // useCircuitData
  sortedCircuits: [],
  circuitFilterTypes: [],
  // useFilter
  searchTerm: '',
  setSearchTerm: () => { },
  betaChecked: {},
  onBetaCheckboxChange: () => { },
  filteredCircuitsCount: 0,
  // usePagination
  pagination: 1,
  setPagination: () => { },
  pageCount: 1,
  pageRangeLow: 0,
  pageRangeHigh: 0,
  // other
  displayCircuits: [],
  loading: true,
});
BrowseContext.displayName = 'BrowseContext';
const { Provider } = BrowseContext;

export function BrowseContextProvider({ children }) {
  const [loading, setLoading] = useState(true);
  const {
    sortedCircuits,
    circuitFilterTypes,
  } = useCircuitData();
  const {
    searchTerm,
    setSearchTerm,
    betaChecked,
    onBetaCheckboxChange,
    filteredCircuits,
    filteredCircuitsCount,
  } = useFilter(sortedCircuits);
  const {
    pagination,
    setPagination,
    pageCount,
    pageRangeLow,
    pageRangeHigh,
    pageSliceStart,
    pageSliceEnd,
  } = usePagination(filteredCircuits.length);

  const displayCircuits = useMemo(
    () => filteredCircuits.slice(pageSliceStart, pageSliceEnd),
    [filteredCircuits, pageSliceStart, pageSliceEnd]
  );

  const contextValue = useMemo(() => ({
    // useCircuitData
    sortedCircuits,
    circuitFilterTypes,
    // useFilter
    searchTerm,
    setSearchTerm,
    betaChecked,
    onBetaCheckboxChange,
    filteredCircuitsCount,
    // usePagination
    pagination,
    setPagination,
    pageCount,
    pageRangeLow,
    pageRangeHigh,
    // other
    displayCircuits,
    loading,
  }), [
    // useCircuitData
    sortedCircuits,
    circuitFilterTypes,
    // useFilter
    searchTerm,
    setSearchTerm,
    betaChecked,
    onBetaCheckboxChange,
    filteredCircuitsCount,
    // usePagination
    pagination,
    setPagination,
    pageCount,
    pageRangeLow,
    pageRangeHigh,
    // other
    displayCircuits,
    loading,
  ]);

  useEffect(() => {
    if (loading) {
      setLoading(false);
    }
  }, [loading, setLoading]);

  return <Provider value={contextValue}>{children}</Provider>;
}

export const useBrowseContext = () => useContext(BrowseContext);

export default BrowseContext;