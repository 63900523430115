import { PADDINGS, SPACINGS } from "../config";

const getHeaderHeight = (headerText, ctx) => {
  const numberOfTextLines = 1; // future: add suport for multi-line headers
  
  return (
    (PADDINGS.HEADER.TEXT.VERTICAL * 2) + 
    (numberOfTextLines * SPACINGS.HEADER_PER_TEXT_LINE)
  );
};

export default getHeaderHeight;