import { FILL_STYLES } from "../config";
import writeChipHeaderName from "./writeChipHeaderName";

const drawChipHeader = (
  ctx,
  headerText,
  offsetLeft,
  chipBoardWidth,
  headerHeight,
  bodyHeight,
) => {
  const borderRadius = bodyHeight > 0 ? [8, 8, 0, 0] : [8, 8, 8, 8];
  ctx.beginPath();
  ctx.fillStyle = FILL_STYLES.HEADER_BACKGROUND;
  ctx.roundRect(offsetLeft, 0, chipBoardWidth, headerHeight, borderRadius);
  ctx.fill();

  writeChipHeaderName(ctx, headerText, offsetLeft, chipBoardWidth);
};

export default drawChipHeader;