import React, {
  useContext, useState
} from 'react';
import { navigationPaths } from '../../constants';
import { useWindowDimensions } from '../../hooks';

const AppContext = React.createContext({});
AppContext.displayName = 'AppContext';
const { Provider } = AppContext;

export function AppContextProvider({ children }) {
  const [state, setState] = useState({});
  const [activeNavTab, setActiveNavTab] = useState(navigationPaths.HOME);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const { width, height } = useWindowDimensions();

  const value = {
    activeNavTab,
    setActiveNavTab,
    state,
    setState,
    isNavOpen,
    setIsNavOpen,
    width,
    height,
  };

  return <Provider value={value}>{children}</Provider>;
}

export const useAppContext = () => useContext(AppContext);

export default AppContext;