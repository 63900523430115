const scaleCanvas = (targetWidth, targetHeight, canvas, ctx) => {
  const maxWidth = parseInt(canvas.offsetWidth, 10);

  const displayWidth = Math.min(targetWidth, maxWidth);

  const smallCanvasWidthScale = displayWidth / targetWidth;
  const displayHeight = targetHeight * smallCanvasWidthScale;

  const pixelRatioScale = window.devicePixelRatio;
  canvas.style.width = `${displayWidth}px`;
  canvas.style.height = `${displayHeight}px`;

  canvas.width = Math.floor(displayWidth * pixelRatioScale);
  canvas.height = Math.floor(displayHeight * pixelRatioScale);

  const drawScale = smallCanvasWidthScale * pixelRatioScale;
  ctx.scale(drawScale, drawScale);
}

export default scaleCanvas;