import { Link } from "react-router-dom";
import { useAppContext } from "../../context/AppContext";
import { classNames } from "../../utils";

const NavButton = ({ name = 'Default', path = 'default', Icon }) => {
  const { activeNavTab, setIsNavOpen } = useAppContext();

  return (
    <Link
      to={path}
      className={classNames([
        ['nav-button', true],
        ['nav-button-active', activeNavTab === path]
      ])}
      onClick={() => setIsNavOpen(false)}
    >
      <div className='nav-button-icon'>
        <Icon stroke='white' fill='white' />
      </div>
      <div className='nav-button-label'>
        <span>
          {name}
        </span>
      </div>
    </Link>
  )
};

export default NavButton