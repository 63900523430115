import { useState } from 'react';
import { navigationPaths } from '../../constants';
import { useNavSync } from '../../hooks';
import '../../styles/CircuitGenerator.css';
import Canvas from './Canvas';
import SEO from '../SEO';

const CircuitGenerator = () => {
  useNavSync(navigationPaths.CIRCUIT_GENERATOR);
  const [headerText, setHeaderText] = useState('');

  return (
    <>
      <SEO
        title='Circuit Generator'
        description='Create your own Rec Room circuit with our user-friendly tool.'
      />
      <div className='app-circuit-generator'>
        <p style={{ maxWidth: 600, textAlign: 'center' }}>
          This page will be a place where you can generate existing or new circuit boards. Stay tuned!
        </p>
        <div className="cgen-input">
          <input
            type="text"
            name="circuit-header-text"
            className="text-input"
            placeholder="Customize circuit header..."
            value={headerText}
            onChange={(event) => setHeaderText(event.target.value)}
          />
        </div>
        <div>
          <Canvas headerText={headerText} />
        </div>
      </div>
    </>
  );
}

export default CircuitGenerator;
