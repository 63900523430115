import PORT_TYPES from "../../../constants/circuits/portTypes";
import { FILL_STYLES, FONTS, LETTER_SPACING, PADDINGS, SPACINGS } from "../config";
import { getHeaderTextWriteCoordinates } from "../util";
import drawInputPort from "./drawInputPort";
import drawOutputPort from "./drawOutputPort";

const writeChipPortLabels = (
  ctx,
  functions,
  offsetLeft,
  offsetTop,
  chipBoardWidth
) => {
  ctx.font = FONTS.PORT_LABEL;
  ctx.letterSpacing = LETTER_SPACING.PORT_LABEL_TEXT;
  ctx.fillStyle = FILL_STYLES.PORT_LABEL_TEXT;
  ctx.lineJoin = 'round';
  ctx.strokeStyle = '#666';
  ctx.lineWidth = 2;

  let currentOffsetTop = offsetTop + PADDINGS.BODY_VERTICAL;
  const inputHorizontalOffset = offsetLeft + PADDINGS.BODY_HORIZONTAL;
  const outputHorizontalOffset = offsetLeft + chipBoardWidth - PADDINGS.BODY_HORIZONTAL;

  functions.forEach(({ inputs, outputs }) => {
    const portRange = Math.max(inputs.length, outputs.length);

    for (let portIndex = 0; portIndex < portRange; portIndex++) {
      const input = inputs[portIndex];
      const output = outputs[portIndex];

      currentOffsetTop += SPACINGS.PORT_PER_ROW;
      const textOffsetTop = currentOffsetTop - (SPACINGS.PORT_PER_ROW / 2);

      if (input) {
        ctx.textAlign = 'left';
        ctx.fillStyle = FILL_STYLES.PORT_LABEL_TEXT
        ctx.strokeText(input.portLabel, inputHorizontalOffset, textOffsetTop);
        ctx.fillText(input.portLabel, inputHorizontalOffset, textOffsetTop);

        drawInputPort(ctx, input, inputHorizontalOffset, currentOffsetTop);
      }

      if (output) {
        ctx.textAlign = 'right';
        ctx.fillStyle = FILL_STYLES.PORT_LABEL_TEXT
        ctx.strokeText(output.portLabel, outputHorizontalOffset, textOffsetTop);
        ctx.fillText(output.portLabel, outputHorizontalOffset, textOffsetTop);

        drawOutputPort(ctx, output, outputHorizontalOffset, currentOffsetTop);
      }
    }

    currentOffsetTop += SPACINGS.FUNCTION_SPLIT;
  });
};

export default writeChipPortLabels;