import FilterGroupHeading from "./FilterGroupHeadings";

const FilterGroup = ({ groupLabel, children }) => {
  return (
    <div className='filter-group'>
      <FilterGroupHeading label={groupLabel} />
      {children}
    </div>
  );
};

export default FilterGroup;