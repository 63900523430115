import { FILL_STYLES } from "../config";
import writeChipPortLabels from "./writeChipPortLabels";

const drawChipBody = (
  ctx,
  functions,
  offsetLeft,
  offsetTop,
  chipBoardWidth,
  bodyHeight
) => {
  ctx.beginPath();
  ctx.fillStyle = FILL_STYLES.BODY_BACKGROUND;
  ctx.roundRect(offsetLeft, offsetTop, chipBoardWidth, bodyHeight, [0, 0, 8, 8]);
  ctx.fill();

  writeChipPortLabels(ctx, functions, offsetLeft, offsetTop, chipBoardWidth);
}

export default drawChipBody;