const SearchBox = ({ applySearch, searchTerm }) => {


  return (
    <div className='filter-search-box'>
      <input
        type='text'
        placeholder='eg: List Create'
        value={searchTerm}
        onChange={applySearch}
        style={{
          fontSize: 13,
          padding: 12
        }}
      />
    </div>
  );
};

export default SearchBox;