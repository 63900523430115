import { FILL_STYLES, SPACINGS } from "../config";

const drawInputPortWithValue = (
  ctx,
  horizontalOffset,
  verticalOffset,
  defaultValue = '',
) => {
  // basic port
  ctx.beginPath();
  ctx.roundRect(
    horizontalOffset - 22,
    verticalOffset - SPACINGS.PORT_PER_ROW + 3,
    16,
    12,
    [2, 2, 2, 2]
  );
  ctx.fill();

  // connector
  ctx.beginPath();
  ctx.roundRect(
    horizontalOffset - 29,
    verticalOffset - SPACINGS.PORT_PER_ROW + 6,
    8,
    6,
    [0, 0, 0, 0],
  )
  ctx.fill();

  const textWidth = ctx.measureText(defaultValue).width;

  // value box outer
  ctx.beginPath();
  ctx.roundRect(
    horizontalOffset - 28,
    verticalOffset - SPACINGS.PORT_PER_ROW + 1,
    -14 - textWidth,
    16,
    [2, 2, 2, 2]
  );
  ctx.fill();

  // value box inner
  ctx.beginPath();
  ctx.roundRect(
    horizontalOffset - 31,
    verticalOffset - SPACINGS.PORT_PER_ROW + 3,
    -9 - textWidth,
    12,
    [2, 2, 2, 2]
  );
  ctx.fillStyle = '#444';
  ctx.fill();

  ctx.textAlign = 'right';
  ctx.fillStyle = FILL_STYLES.PORT_VALUE_TEXT;
  // ctx.strokeText(defaultValue, horizontalOffset - 36, verticalOffset - SPACINGS.PORT_PER_ROW + 10);
  ctx.fillText(defaultValue, horizontalOffset - 35, verticalOffset - SPACINGS.PORT_PER_ROW + 10)
};


export default drawInputPortWithValue;