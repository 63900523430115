import { classNames } from "../../../utils";

const FilterHeader = ({ isOpen, toggleIsOpen }) => {
  return (
    <div className='filter-header' onClick={toggleIsOpen}>
      <span>Filter & Search</span>
      <div className={classNames([
        ['filter-arrow', true],
        ['filter-arrow-down', !isOpen],
        ['filter-arrow-up', isOpen],
      ])}>
        <div></div>
      </div>
    </div>
  );
};

export default FilterHeader;