import { SPACINGS } from "../config";

const drawOutputPortExec = (
  ctx,
  horizontalOffset,
  verticalOffset,
) => {
  const portHeight = 16;
  const builtInHorizontalOffset = -21;
  const builtInPortBodyWidth = 17;
  const builtInPortPointWidth = 6;

  const calcHorizontal = (offset) => {
    return horizontalOffset - builtInHorizontalOffset - offset;
  }

  const calcVertical = (offset) => {
    return verticalOffset - SPACINGS.PORT_PER_ROW + 1 + offset
  }

  ctx.beginPath();
  ctx.moveTo(
    calcHorizontal(builtInPortBodyWidth),
    calcVertical(0),
  );
  ctx.lineTo(
    calcHorizontal(0),
    calcVertical(0),
  );
  ctx.lineTo(
    calcHorizontal(builtInPortPointWidth * -1),
    calcVertical(portHeight / 2),
  );
  ctx.lineTo(
    calcHorizontal(0),
    calcVertical(portHeight),
  );
  ctx.lineTo(
    calcHorizontal(builtInPortBodyWidth),
    calcVertical(portHeight),
  );
  ctx.lineTo(
    calcHorizontal(builtInPortBodyWidth),
    calcVertical(0),
  );
  ctx.closePath();
  ctx.fill();
};

export default drawOutputPortExec;