import { useMemo } from "react";
import FilterPreviewItem from "./FilterPreviewItem";

const FilterPreview = ({
  searchTerm,
  setSearchTerm,
  betaChecked,
  onBetaCheckboxChange,
}) => {
  const dismissSearchTerm = () => setSearchTerm('');
  const dismissBeta = () => onBetaCheckboxChange('any');

  const previews = useMemo(() => {
    const previewOptions = [];

    if (searchTerm.length) {
      previewOptions.push(
        <FilterPreviewItem
          label={searchTerm}
          onDismiss={dismissSearchTerm}
        />
      );
    }

    if (betaChecked.isBeta) {
      previewOptions.push(
        <FilterPreviewItem
          label='Is Beta'
          onDismiss={dismissBeta}
        />
      );
    }

    if (betaChecked.isNotBeta) {
      previewOptions.push(
        <FilterPreviewItem
          label='Is Not Beta'
          onDismiss={dismissBeta}
        />
      );
    }

    return previewOptions;
  }, [searchTerm, betaChecked]);


  return (
    <div className='filter-preview'>
      {previews}
    </div>
  );
};

export default FilterPreview;