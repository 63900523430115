import { useEffect, useState } from "react";
import { debounce } from "../utils";

const getWindowDimensions = () => ({
  width: window.innerWidth,
  height: window.innerHeight,
});

const useWindowDimensions = () => {
  const [dimensions, setDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    const resizeHandler = debounce(() => {
      setDimensions(getWindowDimensions());
    }, 100);

    window.addEventListener('resize', resizeHandler);

    return () => window.removeEventListener('resize', resizeHandler);
  }, []);

  return dimensions;
};

export default useWindowDimensions;