import { navigationPaths } from '../../constants';
import { BrowseContextProvider, useBrowseContext } from '../../context/BrowseContext';
import { useNavSync } from '../../hooks';
import SEO from '../SEO';
import CircuitList from './CircuitList';
import Filters from './Filters';
import Pagination from './Pagination';

const CircuitBrowserConsumer = () => {
  useNavSync(navigationPaths.CIRCUIT_BROWSER);
  const {
    // useCircuitData
    // sortedCircuits,
    circuitFilterTypes,
    // useFilter
    searchTerm,
    setSearchTerm,
    betaChecked,
    onBetaCheckboxChange,
    filteredCircuitsCount,
    // usePagination
    pagination,
    setPagination,
    pageCount,
    pageRangeLow,
    pageRangeHigh,
    // other
    displayCircuits,
  } = useBrowseContext();

  return (
    <div className='circuit-browser'>
      <Filters
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        setPagination={setPagination}
        betaChecked={betaChecked}
        onBetaCheckboxChange={onBetaCheckboxChange}
      />
      <Pagination
        numPages={pageCount}
        pagination={pagination}
        setPagination={setPagination}
        side='top'
      />
      <CircuitList
        displayCircuits={displayCircuits}
        displayRangeLow={pageRangeLow}
        displayRangeHigh={pageRangeHigh}
        filteredCircuitsCount={filteredCircuitsCount}
      />
      <Pagination
        numPages={pageCount}
        pagination={pagination}
        setPagination={setPagination}
        side='bottom'
      />
    </div>
  );
};

const CircuitBrowser = () => (
  <BrowseContextProvider>
    <SEO
      title='Circuit Browser'
      description='Browse all official and user created circuits. Learn about the different ports and data types available.'
    />
    <CircuitBrowserConsumer />
  </BrowseContextProvider>
);

export default CircuitBrowser;
