import getBodyDimensions from "./getBodyDimensions";
import getHeaderHeight from "./getHeaderHeight";
import getHeaderWidth from "./getHeaderWidth";
import getPortDimensions from "./getPortDimensions";
import getPortsWidth from "./getPortsWidth";

const getChipDimensions = (chipData, ctx) => {
  const {
    headerText,
    functions,
    inputs,
    outputs
  } = chipData;

  const headerWidth = getHeaderWidth(headerText, ctx);
  const headerHeight = getHeaderHeight(headerText, ctx);

  const { 
    bodyWidth,
    bodyHeight,
  } = getBodyDimensions(ctx, functions);

  const chipBoardWidth = Math.max(headerWidth, bodyWidth);

  const {
    inputPortsWidth,
    outputPortsWidth,
  } = getPortDimensions(ctx, functions);

  const totalChipWidth = chipBoardWidth + inputPortsWidth + outputPortsWidth;
  const totalChipHeight = headerHeight + bodyHeight;

  return {
    headerHeight,
    bodyHeight,
    chipBoardWidth,
    inputPortsWidth,
    totalChipWidth,
    totalChipHeight,
  }
};

export default getChipDimensions;