import MenuButton from "./MenuButton";

const Header = () => {


  return (
    <div className="app-header">
      <MenuButton />
      <div className="header-content">
        <div>
          <span>Rec Room Circuits</span>
        </div>
      </div>
    </div>
  )
};

export default Header;

