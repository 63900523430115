import React, { useEffect } from "react";
import { navigationItems } from "../../constants";
import { useAppContext } from "../../context/AppContext";
import NavButton from "./NavButton";
import { useLocation } from "react-router-dom";

const Navigation = () => {
  const { isNavOpen } = useAppContext();
  const location = useLocation();

  const navButtons = navigationItems
    .filter(({ enabled }) => enabled)
    .sort((a, b) => a.sort - b.sort)
    .map(({ name, path, icon }) => (
      <NavButton
        key={name}
        name={name}
        path={path}
        Icon={icon}
      />
    ));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <div className="navigation">
        <div className='nav-header-controls'>

        </div>
        <div className='nav-buttons'>
          {navButtons}
        </div>
      </div>
      {isNavOpen && (
        <div className='nav-overlay'>

        </div>
      )}
    </>
  )
};

export default Navigation;

