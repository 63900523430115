import { drawChipHeader } from "./draw";
import drawChipBody from "./draw/drawChipBody";
import {
  scaleCanvas,
  getChipDimensions,
  clearCanvas,
} from "./util";

const drawChip = (chipData, ctx, canvas, options = {}) => {
  const {
    headerText,
    functions,
    inputs,
    outputs
  } = chipData;

  const {
    headerHeight,
    bodyHeight,
    chipBoardWidth,
    inputPortsWidth,
    totalChipWidth,
    totalChipHeight,
  } = getChipDimensions(chipData, ctx);

  // Handles device pixel ratio and small canvas width scaling
  scaleCanvas(totalChipWidth, totalChipHeight, canvas, ctx);

  clearCanvas(ctx);

  ctx.textBaseline = 'middle';
  drawChipHeader(ctx, headerText, inputPortsWidth, chipBoardWidth, headerHeight, bodyHeight);
  drawChipBody(ctx, functions, inputPortsWidth, headerHeight, chipBoardWidth, bodyHeight);
};

export default drawChip;