import FilterGroup from './FilterGroup';
import SearchBox from './SearchBox';
import ToggleOptions from './ToggleOptions';
import FilterCategories from './FilterCategories';

const FilterBody = ({
  searchTerm,
  setSearchTerm,
  setPagination,
  betaChecked,
  onBetaCheckboxChange,
}) => {
  const applySearch = (e) => {
    setSearchTerm(e.target.value);
    setPagination(1);
  }

  const applyBetaChange = (checkboxKey) => {
    onBetaCheckboxChange(checkboxKey);
    setPagination(1);
  }

  return (
    <div className='filters'>
      <div>
        <FilterGroup groupLabel='Search by chip name'>
          <SearchBox
            applySearch={applySearch}
            searchTerm={searchTerm}
          />
        </FilterGroup>

        {/* Chip Beta Toggle */}
        <FilterGroup groupLabel='Beta Status'>
          <ToggleOptions
            betaChecked={betaChecked}
            onBetaCheckboxChange={applyBetaChange}
          />
        </FilterGroup>

        {/* Categories! */}
        {/* <FilterGroup groupLabel='Categories'>
          <FilterCategories />
        </FilterGroup> */}
      </div>

    </div>
  );
};

export default FilterBody;