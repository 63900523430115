const FilterCheckbox = ({
  checkboxKey,
  label,
  checked,
  onCheckboxChange,
  type = 'checkbox',
}) => {
  const onChange = () => onCheckboxChange(checkboxKey);

  return (
    <div className='filter-checkbox'>
      <div>
        <input
          type={type}
          checked={checked}
          onChange={onChange}
        />
      </div>
      <div onClick={onChange}>
        <label>{label}</label>
      </div>
    </div>
  );
};

export default FilterCheckbox;