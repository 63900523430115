import { useEffect, useLayoutEffect } from "react";
import { useAppContext } from "../../context/AppContext";
import { useCanvas } from "../../hooks";
import { drawCircuit } from "../../utils";

// const drawStrokedText = (x, y, text, ctx) => {
//   ctx.font = "500 18px Arial"
//   ctx.strokeStyle = '#5B595A';
//   ctx.lineWidth = 4;
//   ctx.lineJoin = "miter";
//   ctx.miterLimit = 3;
//   ctx.strokeText(text, x, y);
//   ctx.fillStyle = '#eeeeee';
//   ctx.fillText(text, x, y);
// }





const Canvas = ({ headerText }) => {
  const { width, height } = useAppContext();
  const { canvasRef, ctx, canvas } = useCanvas();

  useEffect(() => {
    if (ctx !== undefined && ctx) {

      // Trick to fit canvas into parent element size
      canvas.style.width = "100%";
      canvas.style.height = "100%";
      canvas.style.width = canvas.offsetWidth;
      canvas.style.height = canvas.offsetHeight;

      const circuitData = {
        headerText: headerText || undefined
      }
      drawCircuit(ctx, canvas, circuitData);
    }

  }, [canvas, ctx, width, height, headerText]);

  return (
    <canvas
      ref={canvasRef}
    ></canvas>
  );
}

export default Canvas;