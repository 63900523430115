import { useAppContext } from "../../context/AppContext";

const MenuButton = () => {
  const { setIsNavOpen } = useAppContext();

  return (
    <div className="header-menu">
      <div onClick={() => setIsNavOpen((isNavOpen) => !isNavOpen)}>
        <div>
          <div className="header-menu-icon">

          </div>
        </div>
      </div>
    </div>
  )
};

export default MenuButton;

