import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

const usePagination = (itemCount, perPage = 20,) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const pagination = parseInt(searchParams.get('page')) || 1;
  const pageCount = Math.ceil(itemCount / perPage);
  const pageRangeLow = ((pagination - 1) * perPage) + 1;
  const pageRangeHigh = Math.min(itemCount, pageRangeLow + 19);

  const setPagination = useCallback((pageNum) => {
    setSearchParams((prevSearchTerm) => {
      if (pageNum > 1) {
        prevSearchTerm.set('page', pageNum);
      } else {
        prevSearchTerm.delete('page');
      }
      
      return prevSearchTerm;
    });
  }, [setSearchParams]);

  return {
    pagination,
    setPagination,
    pageCount,
    pageRangeLow,
    pageRangeHigh,
    pageSliceStart: (pagination - 1) * perPage,
    pageSliceEnd: pagination * perPage
  };
};

export default usePagination;