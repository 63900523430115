import { SPACINGS } from "../config";

const drawInputPortBasic = (
  ctx,
  horizontalOffset,
  verticalOffset,
) => {
  ctx.beginPath();
  ctx.roundRect(
    horizontalOffset - 22,
    verticalOffset - SPACINGS.PORT_PER_ROW + 3,
    16,
    12,
    [2, 2, 2, 2]
  );
  ctx.fill();
};


export default drawInputPortBasic;